
import {

    ShieldCheckIcon,
    DevicePhoneMobileIcon,
    CodeBracketSquareIcon,
    RectangleGroupIcon,
    GlobeAsiaAustraliaIcon,
    LinkIcon,
    HomeIcon,
    // CubeIcon,
    EnvelopeIcon,
    // Squares2X2Icon,
} from '@heroicons/react/24/outline'




export const navbar = [
    {
        name: 'Home',
        href: '/',
        icon: HomeIcon,
    },
    {
        name: 'UX/UI Design',
        href: '/uxuidesign',
        icon: RectangleGroupIcon,
    },
    {
        name: 'Contact',
        href: '/contact',
        icon: EnvelopeIcon,
    },
]


export const Service = [

    {

        title: "Web Development",
        description: "HTML/CSS, JavaScript Animation, WordPress, Responsive Website",
        style: "w-20 h-20 flex items-center justify-center rounded-full bg-gradient-to-r from-[#FFA8E8] to-[#FFE6E6]",
        iconColor: "#fff",
        icon: CodeBracketSquareIcon

    },
    {

        title: "AI/ML",
        description: "Artificial Intelligence and Machine Learning Services to Optimize Your Business Functions",
        style: "w-20 h-20 flex items-center justify-center rounded-full bg-gradient-to-r from-[#59DBB0] to-[#FEFFB5]",
        iconColor: "#fff",
        icon: ShieldCheckIcon

    },
    {

        title: "App Development",
        description: "Android, ios, flexible to all device build with flutter, react native",
        style: "w-20 h-20 flex items-center justify-center rounded-full bg-gradient-to-r from-[#E7D1FF] to-[#8383EF]",
        iconColor: "#FF9147",
        icon: DevicePhoneMobileIcon

    },
    {

        title: "Block-chain Development",
        description: "None-fungible tokens (NFT), solidity, Media, Money Transfer",
        style: "w-20 h-20 flex items-center justify-center rounded-full bg-gradient-to-r from-[#FFAF73] to-[#FFC990]",
        iconColor: "#9A3FC7",
        icon: LinkIcon

    },
    {

        title: "UI/UX",
        description: "Landing Pages, User Flow, Wireframing, Prototyping, Mobile App Design, Web App",
        style: "w-20 h-20 flex items-center justify-center rounded-full bg-gradient-to-r to-[#9CB3FF] from-[#4C82DE]",
        iconColor: "#fff",
        icon: RectangleGroupIcon

    },
    {

        title: "Branding",
        description: "Visual Identity, Stationary Kit, Marketing Materials",
        style: "w-20 h-20 flex items-center justify-center rounded-full bg-gradient-to-r from-[#FF8989] to-[#FFDDC5]",
        iconColor: "#9A3FC7",
        icon: GlobeAsiaAustraliaIcon

    },
]

export const Whyus = [
    [{
        title: "High Quality Product",
        description: "We use proper methods and high skilled team to develop solution that helps your clients to grow their business",
    },
    {
        title: "Dedicated 24/7 Support",
        description: "we are always here to listen you & We love to solve your problems at any time",
    }],
    [{
        title: "Low pricing",
        description: "we provide our services at lower price with higher quality and support",
    },
    {
        title: "Satisfaction Guarantee",
        description: "we provide 100% Satisfaction Guarantee to your business partners",
    }],
]

export const SatisfiedClients = [
    {
        description: "",
        image: "",
        username: "",
        location: "",
    }
]

export const Technologies = [
    [{
        name: "android",
        url: require("../image/tech/android.svg").default,
    }],
    [{
        name: "flutter",
        url: require("../image/tech/flutter.svg").default,
    },
    {
        name: "java",
        url: require("../image/tech/java.svg").default,
    }],

    [{
        name: "node",
        url: require("../image/tech/node-js.svg").default,
    },
    {
        name: "php",
        url: require("../image/tech/php.svg").default,
    },
    {
        name: "react",
        url: require("../image/tech/react.svg").default,
    }],

    [{
        name: "javascript",
        url: require("../image/tech/javascript.svg").default,
    },
    {
        name: "kotline",
        url: require("../image/tech/kotlin.svg").default,
    }],
    [{
        name: "wordpress",
        url: require("../image/tech/wordpress.svg").default,
    }],
]

export const Tech = [

    [{
        name: "android",
        url: require("../image/tech/android.svg").default,
    }],


    [{
        name: "flutter",
        url: require("../image/tech/flutter.svg").default,
    },
    {
        name: "java",
        url: require("../image/tech/java.svg").default,
    }],


    [{
        name: "javascript",
        url: require("../image/tech/javascript.svg").default,
    },
    {
        name: "kotline",
        url: require("../image/tech/kotlin.svg").default,
    },
    {
        name: "mongodb",
        url: require("../image/tech/mongodb.svg").default,
    }],


    [{
        name: "node",
        url: require("../image/tech/node-js.svg").default,
    },
    {
        name: "php",
        url: require("../image/tech/php.svg").default,
    },
    {
        name: "react",
        url: require("../image/tech/react.svg").default,
    }],

    [{
        name: "redux",
        url: require("../image/tech/redux.svg").default,
    },
    {
        name: "tailwind css",
        url: require("../image/tech/tailwind-css.svg").default,
    }],
    [{
        name: "wordpress",
        url: require("../image/tech/wordpress.svg").default,
    }],
]

export const designs = [
    {
        url: require("../image/ui ux/1.png")
    },
    {
        url: require("../image/ui ux/8.png")
    },
    {
        url: require("../image/ui ux/3.png")
    },
    {
        url: require("../image/ui ux/4.png")
    },
    {
        url: require("../image/ui ux/5.png")
    },
    {
        url: require("../image/ui ux/6.png")
    },
    {
        url: require("../image/ui ux/7.png")
    },
    {
        url: require("../image/ui ux/2.png")
    },
    {
        url: require("../image/ui ux/9.png")
    },
    {
        url: require("../image/ui ux/10.png")
    },
    {
        url: require("../image/ui ux/11.png")
    },
    {
        url: require("../image/ui ux/12.png")
    },
]